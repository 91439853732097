import { forwardRef } from 'react';

const AddedDocumentComponent = forwardRef((props, ref) => {
    const file = props.file;
    return (
        <li
            ref={ref}
            key={file.name}
        >
            {file.name}
            <img
                className={"uploader-spinner"}
                src="./loader.gif" alt="uploading" />
            <span
                onClick={() => props.removeFile()}
                className={"remove-file"}>
            </span>
        </li>
    );
});

export default AddedDocumentComponent;