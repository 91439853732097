import { useEffect, forwardRef } from 'react';

const WindowEvents = forwardRef((props, ref) => {
    
        useEffect(() => {
            const dragEvent = (e) => {
                e.preventDefault();
                var dropzone = document.getElementsByClassName('dropzone');
                if (dropzone.length > 0)
                    if (e.clientX === 0 && e.clientY === 0) {
                        ref.current.classList.remove('drophover');
                    } else {
                        ref.current.classList.add('drophover');
                    }
            }
            window.addEventListener('dragenter', dragEvent);
            window.addEventListener('dragleave', dragEvent);
            return () => {
                window.removeEventListener('dragenter', dragEvent);
                window.removeEventListener('dragleave', dragEvent);
            }
        }, [ref]);
        return null;
    });

export default WindowEvents;