import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef } from 'react';
import SidePanelChannels from './sidepanelchannels';
import SidePanelUsers from './sidepanelusers';
import SidePanelDirect from './sidepaneldirect';
import SidePanelUnfold from './sidepanelunfold';

const SidePanel = forwardRef((props, ref) => {
    const {
        usersOnline,
        setMQTTPublish,
        channelList,
        setTopicClient,
        topicClient,
        loadingChannel,
        userObject
    } = props;

    const menuMargin = 0;
    const panelState = useRef({ menu: -1 });
    const sidePanel = ref;
    const debounceTimeout = useRef(null);
    const panels = useRef([]);
    const accordions = useRef([]);
    const totalPanels = useRef(3);
    const [lastWidth, setLastWidth] = useState(0);
    const [panelOpen, setPanelOpen] = useState([-1, true]);

    const SidePanelChannelsComponent = useMemo(() => {
        return <SidePanelChannels channelList={channelList} setTopicClient={setTopicClient} topicClient={topicClient} loadingChannel={loadingChannel} />
    }, [channelList, setTopicClient, topicClient, loadingChannel]);

    const SidePanelUsersComponent = useMemo(() => {
        return <SidePanelUsers usersOnline={usersOnline} />
    }, [usersOnline]);

    const SidePanelDirectComponent = useMemo(() => {
        return <SidePanelDirect channelList={channelList} setTopicClient={setTopicClient} topicClient={topicClient} />
    }, [channelList, setTopicClient, topicClient]);

    const SidePanelUnfoldComponent = useMemo(() => {
        return <SidePanelUnfold sidePanel={sidePanel} />
    }, [sidePanel]);

    const panelElements = useMemo(() => {
        return [
            {
                title: 'Users Online',
                component: SidePanelUsersComponent,
                className: 'userlist'
            },
            {
                title: 'Direct Messages',
                component: SidePanelDirectComponent,
                className: 'direct-messages'
            },
            {
                title: 'Channels',
                component: SidePanelChannelsComponent,
                className: 'channels'
            },
            {
                title: 'Menu',
                component: SidePanelUnfoldComponent,
                className: 'unfold-bar'
            }
        ]
    }
        , [
            SidePanelUsersComponent,
            SidePanelDirectComponent,
            SidePanelChannelsComponent,
            SidePanelUnfoldComponent
        ]);


    const totalHeaderHeight = useCallback(() => {
        return (panels.current.map(
            header => {
                return header.parentElement.childNodes[0].clientHeight;
            })
            .flat()
            .reduce((a, b) => a + b, 0) + menuMargin);
    }, [panels, menuMargin]);

    const accordionOnClick = useCallback((index, force = false) => {
        panelState.current.menu = index;
        console.log(index);

        if (index === -1)
            panels.current.forEach(panel => {
                console.log(panel);
                console.log(panel.scrollHeight);
                console.log(panel.clientHeight);
                panel.style.marginTop = -(panel.scrollHeight + 2) + 'px';
            });


        var noAnimation = document.getElementsByClassName('no-animation');
        for (var i = 0; i < noAnimation.length; i++) {
            noAnimation[i].classList.remove('no-animation');
        }
        if (index > -1) {
            var totalHeight = totalHeaderHeight();
            var currentMaxHeight = sidePanel.current.clientHeight - totalHeight;
            if (panels.current[index].style.marginTop === menuMargin + 'px' && !force) {
                if (panels.current[index].scrollHeight > (currentMaxHeight)) {
                    panels.current[index].style.marginTop = -(currentMaxHeight + 2) + 'px';
                } else {
                    panels.current[index].style.marginTop = -(panels.current[index].scrollHeight + menuMargin + 2) + 'px';
                }
                accordions.current[index].classList.remove('active');
                panelState.current.menu = -1;
            } else {
                if (panels.current[index].clientHeight > currentMaxHeight) {
                    panels.current[index].style.maxHeight = currentMaxHeight + 'px';
                }

                if (!force) {
                    accordions.current.forEach(accordion => {
                        accordion.classList.remove('active');
                    });
                }
                panels.current.forEach(panel => {
                    panel.style.marginTop = -(panel.scrollHeight + 2) + 'px';
                });
                accordions.current[index].classList.add('active');
                panels.current[index].style.marginTop = menuMargin + 'px';
            }
        }
        if(userObject.current.state === undefined) return;
        userObject.current.state.menu = index;
        setMQTTPublish([
            {
                topic: 'user/' + userObject.current.id,
                message: {
                    id: userObject.current.id,
                    k: userObject.current.k,
                    state: { ...userObject.current.state }
                }
            }
        ]);
    }, [setMQTTPublish, userObject, menuMargin, sidePanel, totalHeaderHeight]);


    useEffect(() => {
        if (!sidePanel || !sidePanel.current) return;
        const resizeObserver = new ResizeObserver(() => {
            clearTimeout(debounceTimeout.current);
            let wasMobile = lastWidth < 540 && lastWidth !== 0 && document.querySelector('.chat-body').clientWidth >= 540;
            if (wasMobile && panelState.current.menu === -1) {
                accordions.current.forEach(accordion => {
                    accordion.classList.add('no-animation');
                    panels.current.forEach(panel => {
                        panel.style.marginTop = -panel.scrollHeight + 'px';
                    });
                    accordion.classList.remove('no-animation');
                });
            }

            setLastWidth(document.querySelector('.chat-body').clientWidth);
            debounceTimeout.current = setTimeout(() => {

                if (!sidePanel.current) return;
                let isMobile = document.querySelector('.chat-body').clientWidth < 540;
                let unfolded = sidePanel.current.classList.contains('unfold');

                if (!isMobile) {
                    sidePanel.current.style.height = '100%';
                } else if (!unfolded) {
                    sidePanel.current.style.height = 0;
                }
                let maxHeight = sidePanel.current.clientHeight - (totalHeaderHeight());
                if (panelState.current.menu > -1)
                    panels.current[panelState.current.menu].style.maxHeight = maxHeight + 'px';

            }, 20);
        });
        resizeObserver.observe(sidePanel.current);

        accordions.current.forEach(accordion => {
            accordion.classList.add('no-animation');
            panels.current.forEach(panel => {
                panel.style.marginTop = -panel.scrollHeight + 'px';
            });
            accordion.classList.remove('no-animation');
        });


        totalPanels.current = panelElements.length + 1;
        return () => resizeObserver.disconnect();
    }, [panelElements, sidePanel, totalHeaderHeight]);


    useEffect(() => {
        new Promise(resolve => {
            accordions.current.forEach((accordion, index) => {
                if (accordion.classList.contains('active') || accordion.classList.contains('channels')) {
                    var totalHeight = totalHeaderHeight();
                    var currentMaxHeight = sidePanel.current.clientHeight - totalHeight;
                    if (panels.current[index].clientHeight > currentMaxHeight) {
                        panels.current[index].style.maxHeight = currentMaxHeight + 'px';
                    }
                }
                resolve(true);
            });
        });
    }, [channelList, sidePanel, totalHeaderHeight]);

    useEffect(() => {
        new Promise(resolve => {
            panels.current.forEach(panel => {
                var height = panel.clientHeight;
                panel.style.marginTop = -height + 'px';
            });
            resolve(true);
        });
    }
        , [panels]);


    useEffect(() => {
        accordionOnClick(panelOpen[0]);
    }
        , [panelOpen, accordionOnClick]);


    useEffect(() => {
        if (userObject.current.state === undefined) return;
        panels.current.forEach(panel => {
            panel.style.marginTop = -panel.scrollHeight + 'px';
        });
        if (userObject.current.state.menu !== -1) {
            accordionOnClick(userObject.current.state.menu, true);
        }
    }
        , [panels, userObject, accordionOnClick]);

    return (
        <div ref={ref} className="chat-references">
            {panelElements.map((panel, index) => {
                return (
                    <div key={index + "_accdiv"}>
                        <div className={'accordion no-animation ' + panel.className} ref={el => accordions.current[index] = el} key={index + "_acc"}>
                            <h4 onClick={() => { setPanelOpen([index, panelOpen === index]) }}>
                                <span>{panel.title}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </h4>
                            <div className={'panel'} ref={el => panels.current[index] = el} style={{ zIndex: panelElements.length - index }}>
                                {panel.component}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default SidePanel;