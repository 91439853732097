import { forwardRef } from 'react';

const ActivityMessage = forwardRef((props, ref) => {
    const { activeUsers, usersOnline } = props;

    function activeUserMessage() {
        if (ref && ref.current !== null && activeUsers.length === 0) {
            ref.current.innerText = '';
            return;
        } else if (activeUsers.length > 1) {
            ref.current.innerText = 'Multiple users are typing';
            return;
        } else if (activeUsers.length === 1) {
            var newActiveMessage = "";
            var alias = '';

            activeUsers.forEach(
                user => {
                    usersOnline.forEach(
                        usera => {
                            if (usera.id === user.id) {
                                alias = usera.alias;
                                return;
                            }
                        }
                    )
                    newActiveMessage += alias + " is typing";
                }
            );
            ref.current.innerText = newActiveMessage;
        }
    }
    activeUserMessage();

    return (
        <div className={"channel-activity"} ref={ref}></div>
    );
});

export default ActivityMessage;