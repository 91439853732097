import React from 'react';

const SidePanelUsers = (props) => {
    const usersOnline = props.usersOnline;
    return (
        <>
            {usersOnline.map((user) => {
                return (
                    <div key={user.id + "uo"}>
                        <span className={'userlist-name'}>
                            {user.alias}
                        </span>
                    </div>
                );
            })}
            {usersOnline.length === 0 ?
                <div>
                    <span>
                        No users online
                    </span>
                </div> : null}
        </>
    );
}

export default SidePanelUsers;