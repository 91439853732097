import React, { useState, useRef } from 'react';
import parse from 'html-react-parser';
import MessageDocumentComponent from './messagedocument';

const MessageLineComponent = props => {

  const { message, data } = props;
  const getHistory = props.data.setGetHistory;
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {message.message.more ?
        <div className='chat-message more-messages' onClick={() => { setLoading(true); getHistory({ ...message.message.more }) }}>
          {!loading ? <span>Load Previous Messages</span> : <span>Loading...</span>}
        </div> : null}
      {message.user ?
        <div ref={messageRef} className={'chat-message'} data-timestamp={message.timestamp}>
          <strong className='message-alias'>
            {message.message.alias}
          </strong>
          <div className='message-time'>
            {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </div>
          {message.delete ?
            <div className='message-options'>
              <span
                onClick={() => {
                  message.edit(message.timestamp);
                }}>
              </span>
              <span
                onClick={() => {
                  message.delete(message.timestamp);
                }}>
              </span>
            </div>
            : null}
          <div className={'message-content'}>
            {parse(message.message.text)}
          </div>
          {message.message.document ?
            ['image', 'document'].map(
              file_type => message.message.document.map((document, index) =>
                <MessageDocumentComponent
                  key={message.timestamp.toString() + index.toString()}
                  attachment={document}
                  data={data}
                  file_type={file_type}
                  index={index}
                />
              ))
            :
            null}
          {message.message.edited ? <div className='message-edited' draggable='false'>edited</div> : null}
        </div >
        : null}
    </>
  );
}

export default MessageLineComponent;