import './styles/chat.css';
import './styles/scss/chat.scss';
import { useState, useEffect, useRef, useReducer, useCallback, useMemo } from 'react';

import mqtt from 'mqtt';
import crypto from 'crypto';

import Quill from './components/quill';
import Info from './components/info';
import FileDropzone from './components/dropzone';
import WindowEvents from './components/windowevents';
import SidePanel from './components/sidepanel/sidepanel';
import MessagePanel from './components/message/messagepanel';
import ActivityMessage from './components/activitymessage';
import ResizeTab from './components/resizetab';

const activeReducer = (prevState, action) => {
  switch (action.type) {
    case 'add':
      const isDuplicate = prevState.some(item => item.id === action.payload.id);
      if (isDuplicate) {
        return prevState;
      }
      return [...prevState, action.payload];
    case 'remove':
      return prevState.filter(item => item.id !== action.payload.id);
    case 'clear':
      return prevState = [];
    default:
      return prevState;
  }
}

function ChatApp() {

  const urlMQTT = 'https://nxgbhxayrpgzfjl6fom2ghhjye0dariu.lambda-url.eu-west-2.on.aws/';

  const [alias, setAlias] = useState('');
  const [channelList, setChannelList] = useState([]);
  const [processPacket, setProcessPacket] = useState(null);
  const [attachedDocuments, setAttachedDocuments] = useState({ document: [] });
  const [lastViewedChannelAt, setLastViewedChannelAt] = useState(null);
  const [subscribeToNewChannel, setSubscribeToNewChannel] = useState(null);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [mqttClient, setMQTTClient] = useState(null);
  const [reconnectCount, setReconnectCount] = useState(null);
  const [mqttPublish, setMQTTPublish] = useState(null);
  const [topicClient, setTopicClient] = useState({ id: null });
  const [getHistory, setGetHistory] = useState(null);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState(null);
  const [editMessageId, setEditMessageId] = useState(null);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [activeUsers, dispatchUsers] = useReducer(activeReducer, []);
  const [usersOnline, dispatchOnline] = useReducer(activeReducer, []);
  const [loading, setLoading] = useState(true);
  const messages = useRef({});
  const userSettings = useRef({});
  const endPoints = useRef({});
  const currentTopic = useRef(null);
  const userObject = useRef({
    id: null,
    t: null,
    alias: null,
  });

  const chatRef = useRef(null);
  const messagePanelRef = useRef(null);
  const quillRef = useRef(null);
  const dropzoneRef = useRef(null);
  const sidePanelRef = useRef(null);
  const activityMessagesRef = useRef(null);
  const messageInFlight = useRef([]);
  const activeTopics = useRef([]);

  const deleteMessage = useCallback(
    timestamp => {
      setDeleteMessageId(timestamp);
    }, []);

  const editMessage = useCallback(
    timestamp => {
      var currentEdit = document.getElementsByClassName('editing');
      if (currentEdit.length > 0) {
        var currentEditTimestamp = currentEdit[0].getAttribute('data-timestamp');
        currentEdit[0].classList.remove('editing');
      }

      if (currentEditTimestamp === timestamp.toString()) {
        setEditMessageId(null);
        setAttachedDocuments({ document: [] });
        quillRef.current.editor.deleteText(0, quillRef.current.editor.getLength());
        quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
      } else {
        const messageElement = document.querySelector(`[data-timestamp="${timestamp}"]`);
        if (messageElement) {
          messageElement.classList.add('editing');
          setEditMessageId(timestamp);
        }
      }
    }, []);

  const addMessage = useCallback(
    message => {
      var newMessages = messages.current,
        messageTopic = message.topic.replace('chat/', '');

      if ('topic' in message) {
        delete message.topic;
        delete message.msgtype;
      }

      if (message.user === userSettings.current.id) {
        message.edit = () => editMessage(message.timestamp);
        message.delete = (timestamp) => deleteMessage(timestamp);
      }

      if (newMessages[messageTopic] === undefined)
        newMessages[messageTopic] = [];

      let existingMessage = newMessages[messageTopic].filter(existing => existing.timestamp === message.timestamp);

      if (existingMessage.length > 0) {
        newMessages[messageTopic] = newMessages[messageTopic].map(existing => {
          if (existing.timestamp === message.timestamp) {
            message.message.edited = true;
            return message;
          }
          return existing;
        });
      } else {
        newMessages[messageTopic] = [message, ...newMessages[messageTopic]];
      }

      messages.current = { ...newMessages };

      if (messageTopic === currentTopic.current) {
        setCurrentMessages([...newMessages[messageTopic]]);
        setLastViewedChannelAt(true);
        if (
          audioEnabled &&
          message.user !== userSettings.current.id
        ) {
          const audio = new Audio('chime.mp3');
          audio.play();
        }
      } else if (message.user !== userSettings.current.id) {
        var channelIndex;
        var channel = channelList.filter((channel, index) => {
          if (channel.id === messageTopic) {
            channelIndex = index;
            return channel.id === messageTopic;
          }
          return false;
        });

        var newChannelList = channelList.filter(channel => channel.id !== messageTopic);

        if (channel.length > 0) {
          channel[0].unread = true;
          newChannelList.splice(channelIndex, 0, channel[0]);
          setChannelList([...newChannelList]);
        }
      }
    }, [audioEnabled, channelList, deleteMessage, editMessage]);

  const onMessage = useCallback(
    async (client, id, topic, message) => {
      new Promise(async resolve => {
        let messageDecoded = JSON.parse(message.toString()),
          processMessage = false;
        switch (topic) {
          case 'user/' + id:
            messageDecoded = decryptMessage(messageDecoded.data, id, userSettings.current.t);
            if ('close' in messageDecoded && messageDecoded.close) {
              client.end();
            } else {
              if (messageDecoded.k !== undefined && messageDecoded.k === userSettings.current.k) {
                if ('topics' in messageDecoded) {
                  var newTopics = [],
                    updatedTopics = messageDecoded.topics.map(topic => {
                      var existingTopic = userSettings.current.topics.filter(
                        newTopic => newTopic.id === topic.id
                      );
                      if (existingTopic.length > 0) {
                        return existingTopic[0];
                      }
                      topic.new = true;
                      newTopics.push(topic);
                      return topic;
                    });
                  userSettings.current.topics = updatedTopics;
                  setChannelList(userSettings.current.topics);
                  if (newTopics.length > 0) {
                    setSubscribeToNewChannel(newTopics.map(topic => topic.id));
                  }
                } else if ('state' in messageDecoded) {
                  userSettings.current.state = messageDecoded.state;
                  userObject.current.state = messageDecoded.state;
                } else if ('alias' in messageDecoded) {
                  userSettings.current.alias = messageDecoded.alias;
                  setAlias(messageDecoded.alias)
                }
              }
            }
            break;
          case 'last-will':
            dispatchOnline({ type: 'remove', payload: { id: messageDecoded.id } });
            break;
          case 'client-connected':
          case 'direct/' + id:
            if (messageDecoded.id !== id) {
              dispatchOnline({ type: 'add', payload: { id: messageDecoded.id, alias: messageDecoded.alias } });
              if (topic === 'client-connected') {
                setMQTTPublish([
                  {
                    topic: 'direct/' + messageDecoded.id,
                    message: {
                      'id': userSettings.current.id,
                      'alias': userSettings.current.alias
                    }
                  }
                ]);
              }
            }
            break;
          default:
            if ('message' in messageDecoded && topic.includes('chat/')) {
              topic = topic.replace('chat/', '');
              var currentAppMessages = messageInFlight.current !== null ? messageInFlight.current : [];
              if (
                'activity' in messageDecoded.message
                && messageDecoded.message.id !== id
                && topic === userSettings.current.state.activeChannel + "/act"
              ) {
                dispatchUsers({
                  type: messageDecoded.message.activity === 'active' ?
                    'add' :
                    'remove',
                  payload: {
                    id: messageDecoded.message.id, alias: messageDecoded.message.alias
                  }
                });
              } else if ('saved' in messageDecoded.message) {
                topic = topic.replace('/save', '');
                if (messages.current[topic] !== undefined) {
                  let messageExists = messages.current[topic].filter(msg => msg.timestamp === messageDecoded.message.saved);
                  if (messageExists.length === 0 || messageExists[0].editing !== messageDecoded.message.editing) {
                    setGetHistory({ topic: topic, single: messageDecoded.message.saved });
                  }
                }
              } else if ('delete' in messageDecoded.message) {
                if (topic in messages.current) {
                  messages.current[topic] = messages.current[topic].filter(message => {
                    return message.timestamp !== messageDecoded.timestamp;
                  });
                  if (topic === currentTopic.current) {
                    setCurrentMessages([...messages.current[topic]]);
                  }
                }
              } else {
                var currentParts = localStorage.getItem('messageParts_' + messageDecoded.timestamp) !== null ?
                  JSON.parse(localStorage.getItem('messageParts_' + messageDecoded.timestamp))
                  : [];

                currentParts.push({ topic: topic, message: message.toString() });

                if (currentParts.length === messageDecoded.message.total) {

                  var totalMessage = '';
                  let currentTopic = userSettings.current.topics.filter(topicVal => topicVal.id === topic);

                  currentParts.sort((a, b) => {
                    return JSON.parse(a.message).message.part - JSON.parse(b.message).message.part;
                  }).forEach(part => {
                    let decodedPart = JSON.parse(part.message);
                    totalMessage += decodedPart.message.text;
                  });

                  totalMessage = decryptMessage(totalMessage, topic, currentTopic[0].key);
                  if (!totalMessage) {
                    return;
                  }

                  localStorage.removeItem('messageParts_' + messageDecoded.timestamp);

                  messageDecoded.message = {
                    text: totalMessage.message,
                    document: totalMessage.document,
                    alias: totalMessage.alias,
                    edited: totalMessage.edited
                  }

                  currentAppMessages.push({ topic: topic, message: JSON.stringify(messageDecoded) });
                  processMessage = true;
                } else {
                  localStorage.setItem('messageParts_' + messageDecoded.timestamp, JSON.stringify(currentParts));
                }
              }
            }

            if (processMessage) {
              messageInFlight.current = currentAppMessages;
            }
            resolve(processMessage);
        }
      }).then((result) => {
        if (result)
          setProcessPacket(true);
      });
    }, [userSettings]);

  const MQTTConnect = useCallback((mqttUrl, id, alias) => {
    let options = {
      will: {
        topic: 'last-will',
        payload: JSON.stringify({ id: id, alias: alias }),
      },
      clientId: id.replaceAll('-', ''),
      protocolId: 'MQTT',
      protocolVersion: 5,
      qos: 0,
      clean: true,
      headers: {
        host: mqttUrl
      }
    };

    try {
      const client = mqtt.connect(mqttUrl, options);
      client.on('connect',
        () => {
          setReconnectCount(null);
          setMQTTPublish([
            {
              topic: 'client-connected',
              message: {
                'id': id,
                'alias': alias
              }
            }
          ]);
        }
      );

      client.on('message',
        (topic, message) => {
          onMessage(client, id, topic, message);
        }
      );

      client.on('error',
        () => {
          setReconnectCount(r => r + 1);
        }
      );

      return client;
    } catch (err) {
      console.log(err)
    }
  }, [onMessage]);

  const decryptMessage = (message, id, key) => {
    try {
      const cryptKey = crypto
        .createHash('sha512')
        .update(id)
        .digest('hex')
        .substring(0, 32)
      const encryptionIV = crypto
        .createHash('sha512')
        .update(key)
        .digest('hex')
        .substring(0, 16)
      const decipher = crypto.createDecipheriv('aes-256-cbc', cryptKey, encryptionIV);
      let decrypted = decipher.update(message, 'base64', 'utf8');
      decrypted += decipher.final('utf8');
      var decryptedMessage = JSON.parse(decrypted);
    } catch (err) {
      console.log(err);
      return false;
    }
    return decryptedMessage;
  }

  const handleLogout = useCallback(async e => {
    e.preventDefault();
    try {
      activeTopics.current.forEach(topicVal => {
        mqttClient.unsubscribe(topicVal);
      });

      mqttClient.end(true);
      await new Promise((resolve) => {
        resolve(true);
        usersOnline.current = [];
        channelList.current = [];
        messageInFlight.current = [];
        activeTopics.current = [];
        messages.current = {};
        userObject.current = {};
        endPoints.current = {};
        currentTopic.current = null;
        currentMessages.current = [];
        activeUsers.current = [];
        usersOnline.current = [];
        userSettings.current = {};
        localStorage.clear();
      }).then(async () => {
        setMQTTClient(null);
        await Promise.all([
          setAlias(''),
          setChannelList([]),
          setCurrentMessages([]),
          setAttachedDocuments({ document: [] }),
          setLastViewedChannelAt(null),
          setTopicClient({ id: null }),
          dispatchOnline({ type: 'clear' }),
          setMQTTPublish(null),
          setSubscribeToNewChannel(null),
          setLoadingChannel(false),
          setDeleteMessageId(null),
          setEditMessageId(null)
        ]);
      });
    } catch (err) {
      window.location.reload();
    }
  }, [activeUsers, channelList, currentMessages, mqttClient, usersOnline]);

  const InfoComponent = useMemo(() => {
    return <Info
      userObject={userObject}
      userSettings={userSettings}
      audioEnabled={audioEnabled}
      setAudioEnabled={setAudioEnabled}
      mqttClient={mqttClient}
      reconnectCount={reconnectCount}
      handleLogout={handleLogout}
      topicClient={topicClient}
      alias={alias}
    />;
  }, [userObject, userSettings, audioEnabled, setAudioEnabled, mqttClient, reconnectCount, topicClient, alias, handleLogout]);

  const MessagePanelComponent = useMemo(() => {
    return <MessagePanel
      messages={currentMessages}
      loadingChannel={loadingChannel}
      userObject={{ ...userObject.current, url: endPoints.current.signed }}
      setGetHistory={setGetHistory}
      topicClient={currentTopic.current}
      loading={loading}
      sidePanelRef={sidePanelRef}
    />;
  }, [currentMessages, loadingChannel, userObject, endPoints, sidePanelRef, loading, setGetHistory]);

  const ActiveUserMessageComponent = useMemo(() => {
    return <ActivityMessage
      ref={activityMessagesRef}
      activeUsers={activeUsers}
      usersOnline={usersOnline}
    />;
  }, [activeUsers, usersOnline, activityMessagesRef]);

  const SidePanelComponent = useMemo(() => {
    return <SidePanel
      ref={sidePanelRef}
      channelList={channelList}
      usersOnline={usersOnline}
      topicClient={topicClient}
      loadingChannel={loadingChannel}
      userObject={userObject}
      setTopicClient={setTopicClient}
      setMQTTPublish={setMQTTPublish}
    />;
  }, [channelList, usersOnline, topicClient, loadingChannel, sidePanelRef, setMQTTPublish]);

  const WindowEventsComponent = useMemo(() => {
    return <WindowEvents ref={dropzoneRef} />
  }, []);

  const DropZoneComponent = useMemo(() => {
    return <FileDropzone
      ref={dropzoneRef}
      setAttachedDocuments={setAttachedDocuments}
      attachedDocuments={attachedDocuments}
      isDisabled={topicClient.id === null}
    />;
  }, [attachedDocuments, dropzoneRef, topicClient]);

  const QuillComponent = useMemo(() => {
    return <Quill
      ref={quillRef}
      messagePanelRef={messagePanelRef}
      dropzoneRef={dropzoneRef}
      attachedDocuments={attachedDocuments}
      setAttachedDocuments={setAttachedDocuments}
      topicClient={topicClient}
      userObject={userObject}
      mqttClient={mqttClient}
      signedEndpoint={endPoints.current.signed}
      editMessageId={editMessageId}
      editMessage={editMessage}
      placeholder={"Enter your message here"}
    />;
  }
    , [attachedDocuments, editMessageId, mqttClient, topicClient, userObject, editMessage]);

  const ResizeComponent = useMemo(() => {
    return <ResizeTab
      chatRef={chatRef}
    />
  }, [chatRef]);

  useEffect(() => {

    const userData = async (settings) => {

      endPoints.current = settings.endpoints;
      delete settings.endpoints;
      userSettings.current = settings;
      userObject.current.id = settings.id;
      userObject.current.alias = settings.alias;
      userObject.current.t = settings.t;
      userObject.current.k = settings.k;
      userObject.current.state = settings.state;

      const requestMetadata = {
        method: 'GET',
        mode: 'cors',
        headers: {
          "Accept": "application/json",
          "Authorization": userObject.current.t,
          "uuid": userObject.current.id
        }
      };

      return await fetch(urlMQTT, requestMetadata)
        .then(res => res.json())
        .then(async result => {
          if (result.message === 'Access denied') {
            return false;
          }

          await Promise.all([
            setAudioEnabled(settings.state.audioEnabled !== undefined ? settings.state.audioEnabled : true),
            setChannelList(settings.topics === null ? [] : settings.topics),
            setAlias(settings.alias)
          ]).then(() => {
            var activeTopic = settings.state.activeChannel;
            if (activeTopic !== null) {
              var fullTopic = settings.topics.filter(topic => topic.id === activeTopic);
              setTopicClient(fullTopic[0])
            }
          });

          const loggedInUser = settings;

          return [result.url, loggedInUser.id, loggedInUser.alias];
        }).then(async result => {
          if (result !== false) {
            return await new Promise(resolve => {
              setMQTTClient(MQTTConnect(...result));
              resolve(true);
            }).then(() => {
              setLoading(false);
              return true;
            }).catch(err => {
              console.log(err);
            })
          }
          return false;
        }).catch(err => {
          console.log(err);
        });
    }
    if (window.hrocChatUser !== undefined) {
      userData(window.hrocChatUser);
    }
  },
    []);

  useEffect(() => {
    function updateLastViewedChannel() {
      var currentTopics = userSettings.current.topics.map(topic => {
        if (topic.id === currentTopic.current) {
          topic.unread = false;
          if (
            topic.id in messages.current
            && messages.current[topic.id][0] !== undefined
          ) {
            topic.viewed = messages.current[topic.id][0].timestamp;
          }
        }
        return topic;
      });
      setMQTTPublish([
        {
          topic: 'user/' + userSettings.current.id,
          message: {
            id: userSettings.current.id,
            k: userSettings.current.k,
            topics: currentTopics
          }
        }
      ]);
      setLastViewedChannelAt(null);
    }
    lastViewedChannelAt && updateLastViewedChannel();
  },
    [lastViewedChannelAt]);

  useEffect(() => {
    function publish() {
      mqttClient && mqttPublish.forEach(item => {
        if (item.topic === 'user/' + userSettings.current.id) {
          const key = crypto
            .createHash('sha512')
            .update(userSettings.current.id)
            .digest('hex')
            .substring(0, 32);
          const encryptionIV = crypto
            .createHash('sha512')
            .update(userSettings.current.t)
            .digest('hex')
            .substring(0, 16);
          const cipher = crypto.createCipheriv('aes-256-cbc', key, encryptionIV);
          let encrypted = cipher.update(JSON.stringify(item.message), 'utf8', 'base64');
          encrypted += cipher.final('base64');
          mqttClient.publish(item.topic, JSON.stringify({ id: userSettings.current.id, data: encrypted }));
        } else {
          mqttClient.publish(item.topic, JSON.stringify(item.message));
        }
        var delayFinish = new Date().getTime() + 80;
        while (new Date().getTime() < delayFinish) { }
      });
      setMQTTPublish(null);
    }
    mqttPublish && publish();
  },
    [mqttPublish, mqttClient]);

  useEffect(() => {
    function inFlight() {
      try {
        messageInFlight.current.forEach(
          message => {
            addMessage(JSON.parse(message.message.toString()));
          });
      } catch (e) {
        console.log(e);
      }
      messageInFlight.current = null;
      setProcessPacket(null);
    }
    processPacket && inFlight();
  },
    [processPacket, addMessage]);

  useEffect(() => {
    function setMQTTsubscribe() {
      let defaultTopics = [
        { 'id': 'client-connected', 'name': 'client-connected' },
        { 'id': 'client-disconnected', 'name': 'client-disconnected' },
        { 'id': 'direct/' + userSettings.current.id, 'name': 'direct-messages' },
        { 'id': 'last-will', 'name': 'last-will' },
        { 'id': 'channel-invites', 'name': 'channel-invites' },
        { 'id': 'channel-updates', 'name': 'channel-updates' },
        { 'id': 'user/' + userSettings.current.id, 'name': 'user-settings' },
      ];
      let myTopics = userSettings.current.topics.map(topicVal => { return { 'id': 'chat/' + topicVal.id, 'name': topicVal.name } });
      let mainTopics = myTopics.concat(defaultTopics);
      let actionTopics = myTopics.map(topicVal => { return topicVal.id + "/act" });
      let savedTopics = myTopics.map(topicVal => { return topicVal.id + "/save" });

      activeTopics.current = [...mainTopics.map(topic => {
        return topic.id;
      }), ...actionTopics, ...savedTopics];

      activeTopics.current.forEach(topic => {
        if (topic !== undefined)
          mqttClient.subscribe(topic);
      });
    }
    mqttClient && setMQTTsubscribe();
  },
    [mqttClient]);

  useEffect(() => {
    function reconnect() {
      if (reconnectCount < 3) {
        setMQTTClient(null);
        MQTTConnect(urlMQTT, userSettings.current.id, userSettings.current.alias);
      }
    }
    reconnectCount && reconnect();
  }, [reconnectCount, MQTTConnect, urlMQTT]);

  useEffect(() => {
    function setTopicChannel() {
      if (currentTopic.current !== undefined
        && currentTopic.current !== null
        && topicClient.id.replace('chat/', '') === currentTopic.current.id
      )
        return;

      var activeChannel = topicClient.id,
        currentSettings = userSettings.current;
      activeChannel = activeChannel.replace('chat/', '');

      if (currentSettings.state.activeChannel === null || currentSettings.state.activeChannel !== activeChannel) {
        currentSettings.state.activeChannel = activeChannel;
        setMQTTPublish([
          {
            topic: 'user/' + userSettings.current.id,
            message: {
              id: userSettings.current.id,
              k: userSettings.current.k,
              state: currentSettings.state
            }
          }
        ]);
      }
      setAttachedDocuments({ document: [] });
      setEditMessageId(null);
      quillRef.current.editor.deleteText(0, quillRef.current.editor.getLength());
      currentTopic.current = activeChannel;
      setGetHistory({ topic: activeChannel });
    }
    topicClient.id && setTopicChannel();
  },
    [topicClient]);

  useEffect(() => {
    function subscribeToChannels() {
      var newSubscriptions = subscribeToNewChannel.map(
        topic => {
          return [
            'chat/' + topic,
            'chat/' + topic + "/act",
            'chat/' + topic + "/save"
          ];
        });
      newSubscriptions = newSubscriptions.flat();
      activeTopics.current = activeTopics.current.concat(newSubscriptions);
      mqttClient.subscribe(newSubscriptions);
      setSubscribeToNewChannel(null)
    }
    subscribeToNewChannel && subscribeToChannels();
  }, [subscribeToNewChannel, mqttClient]);


  useEffect(() => {
    function selectForEditing() {
      if (quillRef.current !== null) {
        let matchingMessage = messages.current[currentTopic.current].filter(
          message => message.timestamp === editMessageId
        );
        if (matchingMessage.length === 0)
          return;

        setAttachedDocuments({ document: matchingMessage[0].message.document });
        quillRef.current.editor.root.innerHTML = matchingMessage[0].message.text;
      }
    }

    editMessageId && selectForEditing();
  },
    [editMessageId]);

  useEffect(() => {
    function deleteUserMessage() {
      let messageItem = {
        alias: userSettings.current.id,
        message: { delete: true },
        timestamp: deleteMessageId,
        topic: 'chat/' + topicClient.id,
        msgtype: 'message'
      };
      mqttClient.publish('chat/' + topicClient.id, JSON.stringify(messageItem));
      return setEditMessageId(null) && setDeleteMessageId(null);
    }
    deleteMessageId && mqttClient && deleteUserMessage()
  },
    [deleteMessageId, mqttClient, topicClient]);

  useEffect(() => {
    async function getChannelHistory() {
      const historyTopic = getHistory.topic;
      var url = endPoints.current.history + "?id=" + historyTopic;

      if ('timestamp' in getHistory) {
        url += "&l=" + getHistory.timestamp
      } else if ('single' in getHistory) {
        url += "&s=" + getHistory.single;
      } else if (historyTopic in messages.current
        && messages.current[historyTopic].length > 0) {
        setLastViewedChannelAt(true);
        setCurrentMessages(messages.current[historyTopic]);
        return;
      }

      if (!getHistory.timestamp)
        setLoadingChannel(true);

      return await fetch(url,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            "Accept": "application/json",
            "Authorization": userSettings.current.t,
            "uuid": userSettings.current.id
          }
        })
        .then(res => {
          if (!res.ok) {
            if (res.status === 404) {
              setLoadingChannel(false);
              messages.current[historyTopic] = [];
              setCurrentMessages([]);
              return { data: [] };
            }
          }
          return res.json();
        })
        .then(results => {
          if (!('data' in results) || results.data.length === 0) {
            setLoadingChannel(false);
            return;
          }
          var currentMessagesVar = historyTopic in messages.current ? messages.current[historyTopic] : [];

          messages.current[historyTopic] = results.data.map(item => {
            if ('alias' in item) {
              if (item.alias === userObject.current.id) {
                item.delete = (timestamp) => { deleteMessage(timestamp); }
                item.edit = () => editMessage(item.timestamp);
              }
            }
            return item;
          });

          if ('timestamp' in getHistory) {
            currentMessagesVar.pop();
            messages.current[historyTopic] = messages.current[historyTopic].concat(currentMessagesVar);
          }

          messages.current[historyTopic] = messages.current[historyTopic].sort((a, b) => {
            return b.timestamp - a.timestamp;
          });

          if (historyTopic === currentTopic.current) {
            setCurrentMessages(messages.current[historyTopic]);
            setLastViewedChannelAt(true);
          }
          setLoadingChannel(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
    getHistory && getChannelHistory() && setGetHistory(null);
  },
    [getHistory, deleteMessage, editMessage]);

  useEffect(() => {
    if (userSettings.current.state === undefined
      || userSettings.current.state.audioEnabled === audioEnabled)
      return;

    setMQTTPublish([
      {
        topic: 'user/' + userSettings.current.id,
        message: {
          id: userSettings.current.id,
          k: userSettings.current.k,
          state: {
            ...userSettings.current.state,
            ...{
              audioEnabled: audioEnabled
            }
          }
        }
      }
    ]);
  },
    [audioEnabled]);
  
  return (<>
    <div ref={chatRef} className={loading > 2 ? "chat loading" : "chat"}
    // onContextMenu={(e) => {
    //   e.preventDefault();
    //   console.log("Right Click");
    // }}
    >
      {WindowEventsComponent}
      {DropZoneComponent}
      <div className='chat-body'>
        {ResizeComponent}
        <div className={"chat-info"}>
          {InfoComponent}
        </div>
        <div className={"chat-side-panel"}>
          {SidePanelComponent}
        </div>
        <div ref={messagePanelRef} className={"chat-message-panel"}>
          {MessagePanelComponent}
        </div>
        <div className={"chat-activity-message"}>
          {ActiveUserMessageComponent}
        </div>
        <div className={"chat-form-message"}>
          {QuillComponent}
        </div>
      </div>
    </div>
  </>);
}

export default ChatApp;