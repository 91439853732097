import React, { useState, useRef } from 'react';
import MessageLine from './messageline';

var userObject;
function areEqual({ message: prevMessage }, { message }) {
    return message === prevMessage;
}

const MessageLineMemo = React.memo(({ message }) => {
    if (message !== undefined) {
        return <MessageLine message={{ message }["message"]} data={userObject} />
    }
}, areEqual);

const MessagePanel = (props) => {
    const [messages, setMessages] = useState([]);
    const [appMessages, setAppMessages] = useState([]);
    const messagePanel = useRef(null);
    const loading = props.loading;

    userObject = { ...props.userObject, ...{ setGetHistory: props.setGetHistory } };

    if (props.messages !== appMessages) {
        setAppMessages(props.messages);
        setMessages([...props.messages]);
    }

    return (
        <div className={"chat-messages-block"} ref={messagePanel}>
            <div className={props.loadingChannel || (messages.length === 0 && !props.loadingChannel) ? "messages-placeholder loading" : "messages-placeholder"}>
                {!loading && props.loadingChannel ? <span>Loading channel...<br /></span> : null}
                {loading ? <span>Loading RV Chat...<br /></span>
                    :
                    <>
                        {messages.length === 0 ? <>
                            {!props.loadingChannel && props.topicClient !== null ? <span>No messages yet<br /></span> : null}
                            {props.topicClient === null && !loading ? <span>Not linked to a channel<br /></span> : null}
                        </> : null}
                    </>
                }
            </div>
            <div className={"chat-messages"} >
                {messages.map(msg => <MessageLineMemo key={msg.timestamp} message={msg} />)}
            </div>
        </div>
    );
}

export default MessagePanel;
