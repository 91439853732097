import React, { useEffect, useState, useRef } from 'react';

const SidePanelUnfold = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const sidePanel = props.sidePanel;
    const menuRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const unfoldBar = sidePanel.current.querySelector('.unfold-bar');
            if (window.getComputedStyle(unfoldBar).display === 'none') {
                sidePanel.current.classList.remove('unfold');
                setMenuOpen(false);
                setTimeout(() => {
                    sidePanel.current.style.height = '100%';
                }, 20);
            }
        });
        resizeObserver.observe(menuRef.current);
        return () => resizeObserver.disconnect();
    }, [sidePanel]);


    useEffect(() => {
        const unfoldBar = sidePanel.current.querySelector('.unfold-bar');
        const isDisplayNone = window.getComputedStyle(unfoldBar).display === 'none';
        if (menuOpen) {
            sidePanel.current.classList.add('unfold');
            if (isDisplayNone) {
                sidePanel.current.style.height = '100%';
            } else {
                var openHeight = document.querySelector('.chat-messages').clientHeight + 11;
                sidePanel.current.style.height = openHeight + 'px';
            }
        } else {
            sidePanel.current.classList.remove('unfold');
            if (isDisplayNone) {
                sidePanel.current.style.height = '100%';
            } else {
                sidePanel.current.style.height = 0;
            }
        }
    }, [menuOpen, sidePanel]);

    return (
        <div className={'chat-unfold'}
            ref={menuRef}
            onClick={() => setMenuOpen(!menuOpen)}
        >
        </div>
    );
}

export default SidePanelUnfold;