import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { useDropzone } from 'react-dropzone'

const FileDropzone = forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);
  const currentFiles = useRef([]);
  const {
    attachedDocuments,
    setAttachedDocuments
  } = props;
  // useEffect(() => {
  //   if (props.deleteFile !== null) {
  //     currentFiles.current = currentFiles.current.filter((file) => file.name !== props.deleteFile.name);
  //     if( props.editMessageDocs.length > 0 )
  //       props.setEditMessageDocs(props.editMessageDocs.filter((file) => file.name !== props.deleteFile.name));
  //     setFiles(currentFiles.current);
  //     props.setAttachedDocuments({ document: currentFiles.current });
  //   }
  // }
  //   , [props.deleteFile]);

  useEffect(() => {
    currentFiles.current = attachedDocuments.document;
  }
    , [attachedDocuments]);

  useEffect(() => {
    setAttachedDocuments({ document: files });
  }
    , [files, setAttachedDocuments]);

  const onDrop = async acceptedFiles => {
    var newFiles = [];
    var filePromises = [];
    ref.current.classList.remove("drophover");
    acceptedFiles.forEach((file) => {
      filePromises.push(new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = function (e) {
            resolve({ name: file.name, contents: e.target.result, type: file.type, file: file });
          }
          reader.readAsArrayBuffer(file);
        } catch (e) {
          reject(e);
        }
      }).then((file) => {
        newFiles.push(file);
        return file;
      }));
    });
    await Promise.all(filePromises);
    currentFiles.current = currentFiles.current.concat(newFiles);
    setFiles(currentFiles.current);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} ref={ref} className={'dropzone'}>
      <input {...getInputProps()} />
      {
        <div className={"dropstyleactive"}>
          <div className={"blind"}>
          </div>
          <div className={"notice"}>
            ☁️
            Drag 'n' drop some files here
          </div>
        </div>
      }
    </div>
  )
});

export default FileDropzone;