import React from 'react';

const SidePanelDirect = (props) => {

    const {channelList, setTopicClient, topicClient} = props;
    const channelName = React.useRef({});
    
    return (
        <>
            {channelList !== undefined && channelList.map((channel) => {
                if (channel.name.substr(channel.id.length - 3, 3) !== 'act' && channel.id.substr(0, 7) === 'chat/d/')
                    return (
                        <div key={channel.id + "ci"} ref={el => channelName.current[channel.id] = el}
                            onClick={(e) => {
                                e.preventDefault();
                                setTopicClient(channel);
                            }}><span className={topicClient.id !== null && topicClient.id === channel.id ? "active-topic" : null}>
                                {channel.name}
                                {channel.unread !== undefined && channel.unread ?
                                    <span className={'new-message-notifications'}>
                                        <span className={'new-message-notice'}></span>
                                    </span>
                                    : null}
                            </span>
                        </div>
                    );
                return false;
            })}
        </>
    )
}

export default SidePanelDirect;