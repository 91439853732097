import React, { useRef, useEffect } from 'react';
import axios from 'axios';

function MessageDocumentComponent(props) {
    const {
        data,
        attachment,
        index,
        file_type
    } = props;
    
    const image_types = ['image/jpeg', 'image/png', 'image/gif'];
    const doc_types = ['application/pdf', 'application/text'];
    const ref = useRef(null);
    useEffect(() => {
        const loadImage = new Promise((resolve, reject) => {
            let id = data.id + attachment.name + index;
            var file = attachment;
            try {
                if (localStorage.getItem(id.replaceAll("-", "")) !== null) {
                    if (ref.current !== null) {
                        ref.current.src = localStorage.getItem(id.replaceAll("-", ""));
                    }
                    resolve(id.replaceAll("-", ""));
                } else {
                    fetch(data.url + '?file=' + encodeURIComponent(file.path) + '&type=get', {
                        method: 'GET',
                        mode: 'cors',
                        headers: {
                            "Accept": "application/json",
                            "Authorization": data.t,
                            "uuid": data.id,
                        }
                    })
                        .then(res => res.json())
                        .then(async tokens => {
                            await axios.get(tokens.url, {
                                responseType: 'blob'
                            }).then(response => {
                                const reader = new FileReader();
                                reader.onload = function (e) {
                                    new Promise(resolve => {
                                        localStorage.setItem(id.replaceAll("-", ""), e.target.result);
                                        resolve(true);
                                    }).then(() => {
                                        if (ref.current !== null) {
                                            ref.current.src = localStorage.getItem(id.replaceAll("-", ""));
                                        }
                                        return;
                                    });
                                }
                                reader.readAsDataURL(response.data);
                            }).catch(error => {
                                let notFound = new Image();
                                notFound.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
                                localStorage.setItem(id.replaceAll("-", ""), notFound.src);
                                if (ref.current !== null) {
                                    ref.current.src = localStorage.getItem(id.replaceAll("-", ""));
                                }
                            });
                        });
                    resolve(id.replaceAll("-", ""));
                }
            } catch (e) {
                console.log(e);
                reject(e);
            }
        }).then((id) => {
            return id;
        });

        ref.current && loadImage.then((id) => {
            return id;
        })
    }, [ref, data, attachment, index]);

    const streamFileDownload = file => {
        return function (e) {
            e.preventDefault();
            let url = data.url + '?file=' + encodeURIComponent(file.path) + '&type=get';
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "Accept": "application/json",
                    "Authorization": data.t,
                    "uuid": data.id,
                }
            })
                .then(res => res.json())
                .then(async tokens => {
                    await axios.get(tokens.url, {
                        responseType: 'blob'
                    })
                        .then(response => {
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            const fileStream = new Blob([response.data], { type: file.type });
                            const url = window.URL.createObjectURL(fileStream);
                            a.href = url;
                            a.download = file.name;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    return (
        <>
            {file_type === 'image' && image_types.includes(attachment.type) ?
                <div key={data.id + index} className={"message-attachment-link message-attachment-link-image"}>
                    <img draggable="false" width={attachment.width} height={attachment.height} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" ref={ref} alt={attachment.name} />
                </div> : null}
            {file_type === 'document' && doc_types.includes(attachment.type) ?
                <div key={data.id + index} className={"message-attachment-link message-attachment-link-download"}>
                    <span draggable="false" className="file-download" onClick={streamFileDownload(attachment)}>{attachment.name}</span>
                </div> : null}
        </>
    )
}

export default MessageDocumentComponent;