import React from 'react';

const InfoComponent = props => {

  const {
    topicClient,
    alias,
    setAudioEnabled,
    audioEnabled,
    reconnectCount
  } = props;

  return (
    <div className={reconnectCount !==null && reconnectCount< 3 ? "chat-header reconnecting" : "chat-header"}>
      <h2>
        {topicClient.id !== null ?
          <>
            <span>{topicClient.name.replace('chat/', '')}
            </span>
          </>
          : <span>No Active Channel Selected</span>}
      </h2>
      <div className="logged-in-as">
        <strong>{alias}</strong>
      </div>
      <div className="audio-option">
        <span onClick={
          () => setAudioEnabled(!audioEnabled)}>{audioEnabled ? '🔊' : '🔇'
          }</span>
      </div>
    </div>
  )
}

export default InfoComponent;