import React, { useState, useRef, useCallback } from 'react';

const ResizeComponent = props => {

  const {
    chatRef
  } = props;

  const [resizeHandle, setResizeHandle] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [lastOpenPosition, setLastOpenPosition] = useState(null);
  const resizeHandleRef = useRef(null);

  const handleMouseMove = useCallback((e) => {
    e.stopPropagation();
    if (resizeHandle) {
      var container = chatRef.current.parentElement;
      var windowWidth = window.innerWidth;
      if (windowWidth - e.clientX > 25) {
        if (lastPosition === null) {
          setLastPosition(e.clientX);
        } else {
          let currentWidth = container.clientWidth;
          let diff = lastPosition - e.clientX;
          if (currentWidth + diff < windowWidth - 50) {
            container.style.width = (currentWidth + diff) + 'px';
          } else {
            container.style.width = windowWidth - 50 + 'px';
          }
          setLastPosition(e.clientX);
        }
      }
    }
  }
    , [chatRef, lastPosition, resizeHandle]);


  const handleDoubleClick = useCallback((e) => {
    var container = chatRef.current.parentElement;
    var windowWidth = window.innerWidth;
    setLastOpenPosition(e.clientX);
    if (container.clientWidth !== 20 && container.clientWidth !== windowWidth - 50) {
      if (e.clientX < window.innerWidth / 2) {
        container.style.width = windowWidth - 50 + 'px';
      } else {
        container.style.width = '20px';
      }
    } else {
      container.style.width = lastOpenPosition + 'px';
    }

  }, []);

  const handleMouseUp = useCallback(() => {
    document.body.style.cursor = 'auto';
    document.body.style.userSelect = 'auto';
    resizeHandleRef.current.classList.remove('active');
    setResizeHandle(null);
  }
    , []);

  const handleMouseDown = useCallback((e) => {
    if (!e) e = window.event;
    e.cancelBubble = true;
    setLastPosition(e.clientX);
    document.body.style.cursor = 'col-resize';
    document.body.style.userSelect = 'none';
    resizeHandleRef.current.classList.add('active');
    if (e.stopPropagation) e.stopPropagation();
    setResizeHandle(true);
  }
    , []);

  const handleMouseLeave = useCallback(() => {
    if (resizeHandle) {
      document.body.style.cursor = 'auto';
      document.body.style.userSelect = 'auto';
      resizeHandleRef.current.classList.remove('active');
      setResizeHandle(null);
    }
  }
    , [resizeHandle]);

  return (
    <div className='resize-handle'
      ref={resizeHandleRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onDoubleClick={handleDoubleClick}
    >
      <div className='resize-handle-inner'></div>
    </div>
  )
}

export default ResizeComponent;